<!-- src/App.vue -->
<template>
  <div id="app">
    <NavBar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import Footer from './components/FooterSection.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    Footer
  },
  created() {
    console.log('App component has been created');
  },
};
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Castoro:ital@0;1&family=Kurale&display=swap');

html {
  font-size: 16px;
  margin: 0;
}

body {
  margin: 0;
  font-family: "Castoro", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

#content {
  padding: 20px;
}

/* Override font family in #app */
#app {
  font-family: "Castoro", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* Optional class for using Kurale font on specific elements */
.kurale-font {
  font-family: "Kurale", serif;
}

</style>
