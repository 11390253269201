<template>
  <footer>
    <div class="section-overlay"></div>
    <div class="footer-content">
      <!-- Logo on the far left -->
      <div class="footer-logo-section">
        <router-link to="/">
          <img class="footer-logo" src="@/assets/images/logo.svg" alt="Company Logo">
        </router-link>
      </div>

      <!-- Site Navigation in the center-left -->
      <div class="footer-nav">
        <h3>Site Directory</h3>
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/services">Services</router-link></li>
          <li><router-link to="/about">About Us</router-link></li>
          <li><router-link to="/contact">Contact</router-link></li>
        </ul>
      </div>
    <!-- Contact Information in the center-right -->
    <div class="footer-contact">
      <h3>Contact Us</h3>
      <a href="tel:0432104570" class="phone-button">0432104570</a>
      <p><a href="mailto:admin@freedomtgs.com.au" class="email-link">admin@freedomtgs.com.au</a></p>
    </div>


      <!-- Additional Info on the far right -->
    </div>

    <div class="footer-bottom">
  <p>
    <a class="footer-link" href="https://checkmatewebservices.com.au/" target="_blank" rel="noopener noreferrer">
      Checkmate Web Services
    </a>
  </p>
</div>

  </footer>
</template>

<script>
export default {
  name: 'FooterSection'
};
</script>

<style scoped>
/* In your CSS file */
.footer-link {
  color: inherit;          /* Inherits color from parent element */
  text-decoration: none;   /* Removes underline */
}

.footer-link:hover {
  color: #86b649;             /* Optional: Add hover effect */
}

.phone-button {
  position: relative;
  display: inline-block;
  padding: 8px 16px;
  background-color: #86b649; /* Green background */
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  z-index: 2;
}

.phone-button:hover {
  background-color: #00332a; /* Darker shade on hover */
}

.email-link {
  color: #ddd; /* Email text color */
  text-decoration: none;
}

.email-link:hover {
  color: #86b649; /* Green on hover */
}

footer {
  border-top: #86b649 5px solid;
  position: relative;
  background-image: url('../assets/images/footer.jpg');
  background-size: cover;
  background-position: center bottom;
  color: white;
  padding: 40px 20px 0;
}

.section-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85); /* Semi-transparent dark overlay */
  z-index: 1;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 2; /* Content above overlay */
}

.footer-logo-section {
  flex: 1;
  max-width: 200px;
  text-align: left;
}

.footer-logo {
  position: relative;
  max-width: 250px; /* Adjust logo size */
  margin-bottom: 10px;
  z-index: 2;
}

.footer-logo-section p {
  z-index: 2;
  margin-top: 10px;
  font-size: 0.9rem;
  color: #ddd;
}

.footer-nav, .footer-contact, .footer-extra {
  flex: 1;
  max-width: 200px;
  padding: 0 15px;
}

.footer-nav h3, .footer-contact h3, .footer-extra h3 {
  position: relative;
  z-index: 2;
  margin-bottom: 15px;
  font-size: 1.1rem;
  color: #86b649;
}

.footer-nav ul {
  position: relative;
  z-index: 2;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-nav ul li {
  margin: 10px 0;
}

.footer-nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 0.95rem;
  transition: color 0.3s ease;
}

.footer-nav ul li a:hover {
  color: #86b649; /* Green color on hover */
}

.footer-contact p, .footer-extra p {
  position: relative;
  z-index: 2;
  font-size: 0.9rem;
  color: #ddd;
  line-height: 1.4;
}

.footer-bottom {
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 10px 20px;
  color: #bbb;
  font-size: 0.8rem;
  z-index: 2;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .footer {
    background-image: url('../assets/images/footer-small.jpg');
  }
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo-section {
    max-width: 250px;
    text-align: center;
    align-items: center;
  }

  .footer-nav, .footer-contact, .footer-extra {
    max-width: 100%;
    text-align: center;
  }

  .footer-nav ul {
    text-align: center;
  }
  @media screen and (max-width: 480px) {
    .footer {
    background-image: url('../assets/images/footer-small.jpg');
  }
  }
}
</style>
