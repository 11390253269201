// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './views/HomePage.vue';
import ServicesPage from './views/ServicesPage.vue';
import AboutUsPage from './views/AboutUsPage.vue';
import ContactPage from './views/ContactPage.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: {
      title: 'Home - Freedom Tree and Garden Services',
      description: 'Freedom Tree and Garden Services offers professional tree and garden care in Adelaide, Australia. Our services include tree pruning, removal, lawn care, and garden design.',
      keywords: 'tree services, garden services, tree pruning, tree removal, stump grinding, Adelaide, lawn care, hedge trimming, garden design',
    },
  },
  {
    path: '/services',
    name: 'ServicesPage',
    component: ServicesPage,
    meta: {
      title: 'Services - Freedom Tree and Garden Services',
      description: 'Explore our comprehensive tree and garden services, including tree pruning, tree removal, stump grinding, lawn care, hedge trimming, mulching, weed removal, and garden design in Adelaide.',
      keywords: 'tree pruning, tree removal, stump grinding, garden removal, lawn care, hedge trimming, mulching, weed removal, garden design, Adelaide',
    },
  },
  {
    path: '/about',
    name: 'AboutUsPage',
    component: AboutUsPage,
    meta: {
      title: 'About Us - Freedom Tree and Garden Services',
      description: 'Learn about Freedom Tree and Garden Services, your trusted experts in tree and garden care based in Adelaide, Australia.',
      keywords: 'about us, Freedom Tree and Garden Services, Adelaide, tree services, garden services',
    },
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage,
    meta: {
      title: 'Contact - Freedom Tree and Garden Services',
      description: 'Get in touch with Freedom Tree and Garden Services for professional tree and garden care in Adelaide, Australia.',
      keywords: 'contact, Freedom Tree and Garden Services, Adelaide, tree services, garden services',
    },
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // Always scroll to the top of the page when navigating
    return { top: 0 };
  },
});
// Set document title based on route meta field
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Freedom Tree and Garden Services'; // Fallback title
  next();
});
export default router;
