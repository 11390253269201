<template>
  <div class="hero">
    <div class="hero-content">      
      <h1>Contact Us</h1>
    </div>
  </div>
  <div class="heading">
    <h1>Call, Text, Or Email us for a free quote!</h1>
  </div>
  <div class="container">
    <div class="section-overlay"></div> 
    <div class="contact">
      <div class="text">
        <div>
          <h2>Phone</h2>
          <a href="tel:0432104570" class="phone-button">0432104570</a>
          <h2>Email</h2>
          <p><a href="mailto:admin@freedomtgs.com.au" class="email-link">admin@freedomtgs.com.au</a></p>
        </div>
      </div>


    
    <div class="form">
    <form target="_blank" action="https://formsubmit.co/admin@freedomtgs.com.au" method="POST">
      <div class="form-group">
        <div class="form-row">
          <div class="col">
            <input type="text" name="name" class="form-control" placeholder="Full Name" required>
          </div>
          <div class="col">
            <input type="email" name="email" class="form-control" placeholder="Email Address" required>
          </div>
          <div class="col">
            <input type="tel" name="phone" class="form-control" placeholder="Phone" required>
          </div>
          <div class="col">
            <input type="text" name="suburb" class="form-control" placeholder="Suburb" required>
          </div>
        </div>
      </div>
      <div class="form-group">
        <textarea placeholder="What can we do for you?" class="form-control" name="message" rows="10" required></textarea>
      </div>
      <button type="submit" class="btn btn-lg btn-dark btn-block">Submit</button>
    </form>
  </div>
  </div>

  </div>
    

</template>
  <script>
  export default {
    name: 'ContactPage'
  };
  </script>
  
  <style scoped>
  /* Add this at the top of your CSS */
*, *::before, *::after {
  box-sizing: border-box;
}
.phone-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #86b649; /* Green background */
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  z-index: 3; /* Ensures the button is above the overlay */
  position: relative;
}

.phone-button:hover {
  background-color: #004d3d; /* Darker shade on hover */
}

.email-link {
  color: #ddd; /* Email text color */
  text-decoration: none;
}

.email-link:hover {
  color: #86b649; /* Green on hover */
}

.hero {
  width: 100%;
  height: 50vh;
  background-image: url('@/assets/images/alt-hero.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center; /* Vertically centers the content */
  justify-content: center; /* Horizontally centers the content */
  border-bottom: #86b649 5px solid;
}

.hero-content {
  text-align: center;
  color: white; /* Optional: change text color if needed */
}

.hero h1 {
  margin: 0; /* Remove any default margins from h1 */
  font-size: 3em; /* Adjust the size to fit your design */
}


.heading {
  text-align: center;
  padding: 20px;
  font-size: 1.5em;
  color: #004d3d; /* Dark green for a natural, fresh look */
  background-color: #f8f9fa; /* Light background to keep the area bright */
  margin-top: -20px; /* Lifts the heading slightly to flow from the hero */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Soft shadow for depth */
  border-bottom: #86b649 5px solid;
  border-top: #86b649 5px solid;
}
/* Dark overlay, behind content */
.section-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent dark overlay */
  z-index: 1; /* Ensure the overlay is behind the content */
}
.container {
  position: relative;
  background-image: url('@/assets/images/garden.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  align-items: center; /* Aligns items at the start of the container */
  justify-content: center;
  flex-wrap: wrap; /* Allows container to wrap elements */
  padding: 100px;
}

.heading {
  background-image: url('@/assets/icons/leaves.png');
}
.contact {
  width: 100%;
  display: flex;
  gap: 100px;
  justify-content: center; /* Ensures space distribution around items */
  flex-wrap: wrap; /* Allows wrapping for responsiveness */
  z-index: 2;


}

.text {
  text-align: left;
  color: white;
  width: auto;
  display: inline-block;
  
}
.text div {
  background-color: rgba(0, 77, 61, 0.8);
  padding: 20px;
  border: #86b649 2px solid;
}
.text h2 {
  margin-top: 5px;
}
.form {
  padding: 20px;
  max-width: 600px;
  width: 100%;
  background-color: rgba(0, 77, 61, 0.8);
  border: #86b649 2px solid;

}

.form-control {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 15px; /* Larger padding for a better user experience */
  border: 1px solid #ccc;
  margin-bottom: 15px; /* Adds space below each input */

  transition: border-color 0.3s; /* Smooth transition for interactions */
}

.form-control:focus {
  border-color: #86b649; /* Highlight focus with the brand's green */
  outline: none; /* Removes default outline */
}

.btn {
  width: 100%;
  padding: 12px 20px;
  background-color: #86b649;
  color: white;
  border: none;

  cursor: pointer; /* Indicates clickable button */
  transition: background-color 0.3s, transform 0.2s; /* Smooth background and press effects */
}

.btn:hover, .btn:focus {
  background-color: #004d3d; /* Darker green on hover for a dynamic effect */
  transform: translateY(-2px); /* Slight lift to indicate button press */
}
@media screen and (max-width: 768px) {
  .hero{
    background-image: url('@/assets/images/alt-hero-small.jpg');
  }
  .container {
    padding: 5px;
  }
  .contact {
    flex-direction: column;
    gap: 20px;
    width: 100%; /* Full width to ensure it fits */
  }
  .text {
    width: 100%;
  }
  .form {
    width: 100%;
  }
  
}
  </style>
  