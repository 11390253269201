<template>
    <nav>

      <div class="logo">
        <router-link to="/"><img src="@/assets/images/logo copy.svg" alt="logo" /></router-link>
    </div>

      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/services">Services</router-link></li>
        <li><router-link to="/about">About Us</router-link></li>
        <li><router-link to="/contact">Contact</router-link></li>
      </ul>
    </nav>
  </template>
  
  <script>
  export default {
    name: 'NavBar'
  };
  </script>
  
  <style scoped>
  /* Add at the top of your CSS */
*, *::before, *::after {
  box-sizing: border-box;
}
  /* Style your navigation bar */
  nav {
    display: flex;
    width: 100%;
    position: fixed;
    top: 0;
    background-color: white;
    padding: 10px;
    z-index: 1000;
    align-items: center; /* Centers logo and links vertically */
    justify-content: space-between; /* Pushes logo and links to opposite ends */
    overflow: hidden;
    border-bottom: #86b649 5px solid;
  }
  ul {

    list-style: none;
    display: flex;
    justify-content: right;
    gap: 50px;
    padding-right: 50px;
  }
  
  li {
    cursor: pointer;
  }
  nav ul li a {
  color: black;
  text-decoration: none;
  font-weight: bold;
}

nav ul li a:hover {
  color: #86b649; /* Example hover color */
}
.logo {
  display: flex;
  align-items: center;
  padding-left: 50px;
  overflow: hidden;
}
.logo img {
  width: 100px; /* Adjust to your desired width */
  height: auto; /* Keeps the aspect ratio */
  object-fit: contain; /* Scales SVG within the given width */
}

/* Mobile devices (max-width: 480px) */
@media screen and (max-width: 768px) {
  /* Hide the logo on mobile */
  .logo {
    display: none;
  }

  /* Center the navigation items */
  nav {
    justify-content: center;
    padding: 0;
  }

  /* Ensure the links remain horizontal */
  ul {
    flex-direction: row;
    gap: 15px;
    padding: 0;
  }

  /* Adjust font size for mobile */
  nav ul li a {
    font-size: 14px;
  }
  
}
  </style>
  