<template>
  <div>
    <div class="hero">

    <img class="hero-logo" src="@/assets/images/logo.svg" alt="Freedom Tree and Garden Services">
    <div class="contact">
        <h2>CONTACT US FOR A FREE QUOTE</h2>
        <router-link to="/contact" class="learn-more-button">Contact Us</router-link>
      </div>
    </div>
    <div class="section section2">
      <div class="section-overlay"></div> 

      <div class="why-choose">
        <h2>YOUR BEST CHOICE FOR TREE AND GARDEN SERVICES</h2>
        <div class="why-choose-items">
          <div class="why-choose-item">
            <img src="@/assets/icons/flexible.svg" alt="Flexible Icon">
            <h3>Flexible</h3>
          </div>

          <div class="why-choose-item">
            <img src="@/assets/icons/affordable.svg" alt="Affordable Icon">
            <h3>Affordable</h3>
          </div>

          <div class="why-choose-item">
            <img src="@/assets/icons/professional.svg" alt="Professional Icon">  
            <h3>Professional</h3>
          </div>

          <div class="why-choose-item">
            <img src="@/assets/icons/experienced.svg" alt="Experienced Icon">
            <h3>Experienced</h3>
          </div>
        </div>
      </div>
      <div class="transform">
        <h1>TRANSFORM YOUR GARDEN TODAY!</h1>
      </div>
      <div class="about">
        <h2>At Freedom Tree and Garden Services, we strive to be Adelaide's go-to provider for tree and garden services, delivering high-quality results at an affordable price.</h2>
        <p>
          At Freedom Tree and Garden Services, we are passionate about transforming outdoor spaces into inspiring environments that enrich your life. With meticulous attention to detail and a dedication to customer satisfaction, we strive to exceed your expectations in every project we undertake.

          We approach each task with the utmost care and respect for nature, employing eco-friendly practices and sustainable methods to preserve and enhance the beauty of our surroundings for generations to come.

          For your peace of mind, we are fully licensed and insured. Trust us to bring your vision to life while maintaining the highest standards of quality and professionalism.
        </p>
        <!-- Learn More button -->
        <router-link to="/about" class="learn-more-button">Learn More</router-link>
      </div>

      <!-- Contact section positioned at the bottom -->
      <div class="contact2">
        <h2>CONTACT US FOR A FREE QUOTE</h2>
        <router-link to="/contact" class="learn-more-button">Contact Us</router-link>
      </div>
      <div class="about-us">
        <router-link to="/about" class="learn-more-button">About Us</router-link>
      </div>

    </div>

    <!-- Other sections -->
    <div class="section section3">      
      <div class="section-overlay"></div> 
      <div class="areas">
        <div class="areas-heading">
          <h2>WE COVER ALL OF ADELAIDE</h2>
        </div>
        <div class="areas-wrapper">
          <ul class="areas-list">
            <li>Adelaide CBD</li>
            <li>Adelaide Hills</li>
            <li>Northern Suburbs</li>
            <li>Southern Suburbs</li>
          </ul>
          <ul class="areas-list">
            <li>North Eastern Suburbs</li>
            <li>Eastern Suburbs</li>
            <li>North Adelaide</li>
            <li>Western Suburbs</li>
          </ul>
        </div>
      </div>


      <div class="services-section">
        <h1>DISCOVER OUR SERVICES</h1>
        <div class="services-grid">
          <div class="service-item">
            <h3>Tree Pruning</h3>
            <p>Trimming away dead or overgrown branches to promote tree health and aesthetics.</p>
          </div>
          <div class="service-item">
            <h3>Tree Removal</h3>
            <p>Safely cutting down and removing trees that are diseased, damaged, or pose a risk.</p>
          </div>
          <div class="service-item">
            <h3>Stump Grinding</h3>
            <p>Grinding tree stumps below the surface to allow for replanting or landscaping.</p>
          </div>
          <div class="service-item">
            <h3>Garden Removal</h3>
            <p>Clearing and disposing of garden and other materials from outdoor spaces to prepare for landscaping or construction projects.</p>
          </div>
          <div class="service-item">
            <h3>Lawn Care</h3>
            <p>Mowing, edging, fertilizing, and overall maintenance of lawns.</p>
          </div>
          <div class="service-item">
            <h3>Hedge Trimming</h3>
            <p>Shaping and maintaining hedges for a tidy and defined landscape.</p>
          </div>
          <div class="service-item">
            <h3>Mulching</h3>
            <p>Applying mulch to garden beds to conserve moisture and prevent weed growth.</p>
          </div>
          <div class="service-item">
            <h3>Weed Removal</h3>
            <p>Removal of weeds using various methods, such as hand-pulling, digging, or the application of herbicides.</p>
          </div>
          <div class="service-item">
            <h3>Garden Design</h3>
            <p>Designing and planning garden layouts for aesthetic appeal and functionality.</p>
          </div>
          

        </div>
        <router-link to="/services" class="learn-more-button services-button">Services</router-link>
      </div>
    </div>
    
    <div class="section section4">      
      <div class="section-overlay"></div> 
      <div class="why-choose2">
        <h2>NO JOB IS TOO SMALL OR LARGE</h2>
      </div>
      <div class="testimonials">
  <div class="testimonials-heading">
    <h2>HEAR FROM OUR HAPPY CUSTOMERS</h2>
  </div>

  <div class="testimonial-item">
    <div class="testimonial-header">
      <span class="testimonial-name">Sarah</span>
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">

    </div>
    <p>"He did a great job at my house, a great service, a lovely man who I would highly recommend. And a great price."</p>
  </div>

  <div class="testimonial-item">
    <div class="testimonial-header">
      <span class="testimonial-name">Harry</span>
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">

    </div>
    <p>"I recently hired Danny to remove a large peppercorn tree including stump grinding from my property. His team did a great job safely removing the tree and getting rid of all the green waste & wood plus he left the area nice and tidy. Danny was very easy to communicate with, his quote was very affordable and work was excellent quality so would highly recommend him to anyone."</p>
  </div>

  <div class="testimonial-item">
    <div class="testimonial-header">
      <span class="testimonial-name">Helen</span>
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">

    </div>
    <p>"Excellent work. Danny is great to deal with and good value especially for Seniors. He's reliable and efficient. Great job Danny!"</p>
  </div>

  <div class="testimonial-item">
    <div class="testimonial-header">
      <span class="testimonial-name">Andrew</span>
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">
      <img src="@/assets/icons/star.png" alt="5 stars" class="testimonial-stars">

    </div>
    <p>"Glad I went with this guy! After being ignored or outrageously over-quoted by others, this bloke came out and did the job no problem at all and for a very reasonable price! Highly recommend!"</p>
  </div>
  
</div>
<div class="contact3">
        <h2>CONTACT US FOR A FREE QUOTE</h2>
        <router-link to="/contact" class="learn-more-button">Contact Us</router-link>
      </div>
    </div>
    <div class="section section5">  
      <div class="why-choose3">
        <h2>ALWAYS ON TIME, ALWAYS PROVIDING HIGH QUALITY</h2>
      </div>    
      <div class="section-overlay"></div> 
      <div class="transform">
        <h1>BEFORE AND AFTER</h1>
      </div>
      <div class="container5">
        
        <div class="before-and-after">
          <div class="job">
            <img src="@/assets/images/palm1.jpg" alt="Palm Tree Before">
            <img src="@/assets/images/palm2.jpg" alt="Palm Tree After">
          </div>

          <div class="job">
            <img src="@/assets/images/fence1.jpg" alt="Fence Line Before">
            <img src="@/assets/images/fence2.jpg" alt="Fence Line After">
          </div>
        </div>
      </div>
      <div class="contact4">
        <h2>CONTACT US FOR A FREE QUOTE</h2>
        <router-link to="/contact" class="learn-more-button">Contact Us</router-link>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  created() {
    console.log('HomePage component has been created');
  }
};
</script>

<style scoped>
/* Add this at the top of your CSS */
*, *::before, *::after {
  box-sizing: border-box;
}

:root {
  --secondary-color:  #86b649; /* Primary color */
  --primary-color: #004d3d; /* Secondary color */
}
body {
  overflow-x: hidden;
}
/* General section styles */
.section {
  position: relative; /* Position the background image */
  width: 100%; /* Full width */
  height: 100vh; /* Full viewport height */
  background-size: cover; /* Ensure the background covers the whole div */
  background-position: center; /* Center the background */
  background-repeat: no-repeat; /* Avoid background repetition */
  background-attachment: fixed;
}
.about-us {
  display: none;
}
/* Dark overlay, behind content */
.section-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent dark overlay */
  z-index: 1; /* Ensure the overlay is behind the content */
}

/* Hero background without overlay */
.hero {
  position: relative; /* Ensure it has its own positioning context */
  width: 100%;
  height: 80vh;
  background-image: url('@/assets/images/sky.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}
.hero-logo {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 3;
  mix-blend-mode: multiply; /* Change this to screen, overlay, etc., to experiment */
  opacity: 0.9; /* Adjust opacity if needed */
}
/* Section 2 adjustments */
.section2 {
  position: relative;
  background-image: url('@/assets/images/garden.jpg');
  padding-bottom: 200px; /* Space to accommodate the .contact section */
}
.transform{
  position: relative;
  text-align: center;
  color: #86b649;
  font-size: 2em;
  padding-top: 20px;
  margin: 20px;
  z-index: 3;
}
/* Why Choose Us section */
.why-choose, .why-choose2, .why-choose3 {
  border-bottom: #86b649 5px solid;
  border-top: #86b649 5px solid;
  position: relative;
  background-color: white;
  background-image: url('@/assets/icons/leaves.png');
  padding: 20px;
  margin: 0 auto;
  z-index: 2; /* Ensure it appears above the overlay */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}


.why-choose h2 {
  text-align: center;
  margin-bottom: 30px;
  color: var(--primary-color);
}

.why-choose-items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  gap: 40px;
  padding: 20px; 
  box-sizing: border-box; 
}

.why-choose-item {
  flex: 1 1 calc(25% - 40px); /* Adjusted flex basis */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px; /* Space between icon and heading */
  box-sizing: border-box;
}


.why-choose-item img {
  width: 100px; /* Icon width */
  height: 100px; /* Icon height */
}

.why-choose-item h3 {
  color: var(--primary-color);
  margin: 0 0 10px 0;
}

.why-choose-item p {
  color: #666;
  margin: 0;
}

/* About section */
.about {
  position: relative;
  background-color: rgba(0, 77, 61, 0.8);
  color: white;
  padding: 40px;
  margin: 2% auto;
  z-index: 2; /* Ensure it appears above the overlay */
  max-width: 1200px;
}

.about p {
  font-size: 1.2em;
}

/* Learn More Button */
.learn-more-button {
  display: inline-block;
  padding: 12px 24px;
  margin-top: 20px;
  background-color: #86b649; /* Button background color */
  color: #fff; /* Text color */
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.learn-more-button:hover {
  background-color: #00332a; /* Darker shade on hover */
}

/* Contact section */
.contact, .contact2, .contact3, .contact4 {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-color: rgba(0, 77, 61, 0.8);
  color: white;
  padding: 40px;
  z-index: 5; /* Above .section-overlay */
  text-align: center;
  margin: 0 auto;
}

/* Adjust z-index of the overlay */
.section-overlay {
  z-index: 1; /* Ensure the overlay is behind the content */
}

/* Other sections' background images */
.section3 {
  background-image: url('@/assets/images/hedge.jpg');
  
}
.areas {
  background-color: white;
  background-image: url('@/assets/icons/leaves.png');
  padding: 40px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  border-bottom: #86b649 5px solid;
  border-top: #86b649 5px solid;
}

.areas-heading {
  text-align: center;
  margin-bottom: 20px; /* Space between heading and lists */
}

.areas-heading h2 {
  font-size: 24px; /* Adjust heading size as necessary */
  color: #004d3d;
}

.areas-wrapper {
  display: flex;
  justify-content: center;
  width: 100%; /* Ensure the two lists take up available width */
}

.areas-list {
  list-style-type: none;
  padding-left: 0;
  margin: 2% 10%; /* Adjust margin as needed to position the lists */
  color: #004d3d;
}

.areas-list li {
  position: relative;
  padding-left: 24px; /* Adjust according to the size of the SVG */
  margin-bottom: 12px; /* Increase space between items slightly for better readability */
  text-align: left;
  color: #004d3d;
}

.areas-list li::before {
  content: url('@/assets/icons/maps-and-flags.svg'); /* Path to your SVG file */
  position: absolute;
  left: 0;
  top: 0;
  width: 16px; /* Adjust size as needed */
  height: 16px; /* Adjust size as needed */
  color: #004d3d;
}


.services-section {
  position: relative;
  color: white;
  text-align: center;
  padding: 40px;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto; /* Center the section */
}

.services-section h1 {
  margin-bottom: 50px;
  color: #86b649;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Fixed 3 columns */
  gap: 20px; /* Space between grid items */
  margin-top: 20px;
  background-color: rgba(0, 77, 61, 0.8);
  padding: 20px;
  border: 5px solid #86b649;
}

.service-item {
  padding: 20px;
  text-align: center;
}

.service-item h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #86b649;
}

.section4 {

  background-image: url('@/assets/images/path.jpg');
}
.testimonials {
  position: relative;
  color: white;
  padding: 40px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  z-index: 2;
}

.testimonials-heading h2 {
  font-size: 2em;
  margin-bottom: 30px;
  color: #86b649;
}

.testimonial-item {
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  color: white;
  text-align: left;
  z-index: 2;
}

.testimonial-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.testimonial-stars {
  width: 20px; /* Adjust size based on your star image */
  height: auto;
  margin-left: 10px;
}

.testimonial-name {
  font-weight: bold;
  font-size: 1.2em;
  color: #86b649;
}

.testimonial-item p {
  font-size: 1em;
  color: white;
  font-style: italic;
}

.section5 {
  background-image: url('@/assets/images/lawnmower.jpg');
}
.container5 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.before-and-after {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  margin:20px

}
.job {
  margin: 20px;
}
.before-and-after img {
  max-width: 100%;
  height: auto;

}
@media screen and (max-width: 385px) {
  .hero {
    background-image: url('@/assets/images/sky-small.jpg');
  }
  .section5 {
  background-image: url('@/assets/images/lawnmower-small.jpg');
  }
  .section4 {
  background-image: url('@/assets/images/path-small.jpg');
  }
.section3 {
  background-image: url('@/assets/images/hedge-small.jpg');
  }
.section2 {
  background-image: url('@/assets/images/garden-small.jpg');
  }
  .hero-logo {
    width: 100%;
    height: auto;
  }
  .transform {
    font-size: 1em;
    margin: 0;
    padding: 0;
  }
  .contact, .contact2 {
    padding: 10px;
  }
  .contact h2 {
    margin: 5px;
  }
  .contact3, .contact4, .contact2 {
    display: none;
  }
  .about {
    display: none;
  }
  .services-grid{
    display: none;
  }
  .testimonials {
    padding: 20px;
  }
  .testimonials-heading h2 {
    display: none;
  }
  .testimonial-item {
    padding: 5px;
    margin: 0;
  }
  .testimonial-item p {
    padding: 5px;
    margin: 0;
    font-size: 14px;
  }
  .testimonials span {
    font-size: 1em;
  }
  .testimonials img {
    width: 15px;
    height: auto;
  }
  .why-choose3, .why-choose2 {
    padding: 5px;
  }
  .services-section h1 {
    margin: 0;
    padding: 0;
  }
  .before-and-after {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
    width: 100%;
  }
  .before-and-after img {
    width: 150px;
    height: auto;
  }
  .job {  
    margin: 5px;
    width: 100%;
  }
}
/* Responsive adjustments */
@media screen and (max-width: 480px) and (min-width: 386px) { 
  .hero{
    background-image: url('@/assets/images/sky-small.jpg');
  }
  .section5 {
  background-image: url('@/assets/images/lawnmower-small.jpg');
  }
.section4 {
  background-image: url('@/assets/images/path-small.jpg');
  }
.section3 {
  background-image: url('@/assets/images/hedge-small.jpg');
  }
.section2 {
  background-image: url('@/assets/images/garden-small.jpg');
  }
  .hero-logo {
    width: 100%;
    height: auto;
  }
  .transform {
    font-size: 1em;
    margin: 0;
    padding: 0;
  }
  .about-us {
    position: relative;
    display: block;
    z-index: 5;
    margin: 0;
  }
  .about {
    display: none;
  }
  .about-button {
    display: none;
  }
  .services-grid{
    display: none;
  }
  .transform h1{
    margin-top: 60px;
  }
  .why-choose2 {
    padding: 5px;
  }
  .why-choose3 {
    padding: 10px;
  }
  .testimonials {
    padding: 10px;
  }
  .testimonials-heading h2 {
    font-size: 1.5em;
    margin: 5px;
  }
  .testimonial-item {
    padding: 5px;
    margin: 0;
  }
  .testimonial-item p {
    padding: 5px;
    margin: 0;
    font-size: 14px;
  }
  .contact, .contact2 {
    padding: 10px;
  }
  .contact h2 {
    margin: 5px;
  }
  .contact3, .contact4, .contact2 {
    display: none;
  }
  .before-and-after {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
    width: 100%;
  }
  .before-and-after img {
    width: 150px;
    height: auto;
  }
  .job {  
    margin: 5px;
    width: 100%;
  }
}
/* Small Devices (Tablets and large phones, less than 768px) */
@media (max-width: 768px) and (min-width: 481px) {
  .hero {
    background-image: url('@/assets/images/sky-small.jpg');
  }
  .section5 {
  background-image: url('@/assets/images/lawnmower-small.jpg');
  }
.section4 {
  background-image: url('@/assets/images/path-small.jpg');
  }
.section3 {
  background-image: url('@/assets/images/hedge-small.jpg');
  }
.section2 {
  background-image: url('@/assets/images/garden-small.jpg');
  }
  .hero-logo {
    width: 100%;
    height: auto;
  }
  .contact3, .contact4, .contact2 {
    display: none;
  }
  .about-us {
    position: relative;
    display: block;
    z-index: 5;
  }
  .services-section h1 {
    margin: 0;
    padding: 0;
  }
  .service-item {
    padding: 10px;
  }
  .before-and-after {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
  }
  .before-and-after img {
  width: 250px;
  height: auto;
  }
  .transform h1{
    margin: 0;
  }
  .job {  
    margin: 5px;
  }
  .contact, .contact2 {
    padding: 10px;
  }
  .about-us {
    display: none;
  }

}
/* Medium Devices (Small laptops and tablets, less than 992px) */
@media (max-width: 992px) and (min-width: 769px) {
  .hero {
    background-image: url('@/assets/images/sky-small.jpg');
  }
  .section5 {
  background-image: url('@/assets/images/lawnmower-small.jpg');
  }
.section4 {
  background-image: url('@/assets/images/path-small.jpg');
  }
.section3 {
  background-image: url('@/assets/images/hedge-small.jpg');
  }
.section2 {
  background-image: url('@/assets/images/garden-small.jpg');
  }
  .hero-logo {
    width: 100%;
    height: auto;
  }
   .contact4, .contact2 {
    display: none;
  }
  .services-section h1 {
    margin-bottom: 20px;
  }

  .services-grid {
    display: grid;
    padding: 5px;
    gap: 0;
  }
  .service-item {
    padding: 10px;
  }
  .before-and-after {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
  }
  .before-and-after img {
  width: 300px;
  height: auto;
  }
  .transform h1{
    margin: 0;
  }
  .job {  
    margin: 5px;
  }
  .contact, .contact2, .contact3 {
    padding: 10px;
  }
  .about-us {
    display: none;
  }

}
/* Large Devices (Laptops, less than 1200px) */
@media (max-width: 2000px) and (min-width: 993px) {
  .hero {
    background-image: url('@/assets/images/sky-small.jpg');
  }
  .section5 {
  background-image: url('@/assets/images/lawnmower-small.jpg');
  }
.section4 {
  background-image: url('@/assets/images/path-small.jpg');
  }
.section3 {
  background-image: url('@/assets/images/hedge-small.jpg');
  }
.section2 {
  background-image: url('@/assets/images/garden-small.jpg');
  }
  .hero-logo {
    width: 100%;
    height: auto;
  }
  .contact4, .contact2, .contact3{
    display: none;
  }
  .services-section {
    padding: 0;
  }
  .services-section h1 {
    display: none;
  }
  .services-grid {
    display: grid;
    padding: 0;
    gap: 0;
  }
  .services-button {
    display: none;
  }
  .service-item {
    padding: 0;
  }
  .before-and-after {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px;
  }
  .before-and-after img {
  width: 300px;
  height: auto;
  }
  .transform h1{
    margin: 0;
  }
  .job {  
    margin: 5px;
  }
  .contact, .contact2, .contact3  {
    padding: 10px;
  }
  .testimonials {
    padding: 10px;
  }
  .testimonials-heading h2 {
    margin: 10px;
  }
  .testimonial-item {
    padding: 10px;
    margin: 0;
  }
  .testimonial-item p {
    padding: 5px;
    margin: 0;
  }
  .about {
    padding: 10px;
  }
  .transform h1 {
    font-size: 1em;
  }
}

</style>
