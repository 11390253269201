// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js'; // Ensure the path and filename are correct

const app = createApp(App);

app.use(router);

app.mount('#app');
console.log('App mounted');