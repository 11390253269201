<template>
    <div class="hero">
      <div class="hero-content">      
        <h1>Our Services</h1>
      </div>
    </div>
    <div class="background">
    <div class="services-section">
        <div class="services-grid">
          <div class="service-item">
            <h3>Tree Pruning</h3>
            <p>Trimming away dead or overgrown branches to promote tree health and aesthetics.</p>
          </div>
          <div class="service-item">
            <h3>Tree Removal</h3>
            <p>Safely cutting down and removing trees that are diseased, damaged, or pose a risk.</p>
          </div>
          <div class="service-item">
            <h3>Stump Grinding</h3>
            <p>Grinding tree stumps below the surface to allow for replanting or landscaping.</p>
          </div>
          <div class="service-item">
            <h3>Garden Removal</h3>
            <p>Clearing and disposing of garden and other materials from outdoor spaces to prepare for landscaping or construction projects.</p>
          </div>
          <div class="service-item">
            <h3>Lawn Care</h3>
            <p>Mowing, edging, fertilizing, and overall maintenance of lawns.</p>
          </div>
          <div class="service-item">
            <h3>Hedge Trimming</h3>
            <p>Shaping and maintaining hedges for a tidy and defined landscape.</p>
          </div>
          <div class="service-item">
            <h3>Mulching</h3>
            <p>Applying mulch to garden beds to conserve moisture and prevent weed growth.</p>
          </div>
          <div class="service-item">
            <h3>Weed Removal</h3>
            <p>Removal of weeds using various methods, such as hand-pulling, digging, or the application of herbicides.</p>
          </div>
          <div class="service-item">
            <h3>Garden Design</h3>
            <p>Designing and planning garden layouts for aesthetic appeal and functionality.</p>
          </div>
          

        </div>
        <router-link to="/contact" class="learn-more-button">Contact Us</router-link>
      </div>
    </div>
    <div>

    </div>
  </template>
  
  <script>
  export default {
    name: 'ServicesPage'
  };
  </script>
  
  <style scoped>
.hero {
  width: 100%;
  height: 50vh;
  background-image: url('@/assets/images/alt-hero.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center; /* Vertically centers the content */
  justify-content: center; /* Horizontally centers the content */
}

.hero-content {
  text-align: center;
  color: white; /* Optional: change text color if needed */
}

.hero h1 {
  margin: 0; /* Remove any default margins from h1 */
  font-size: 3em; /* Adjust the size to fit your design */
}
.background {
  background-image: url('@/assets/icons/leaves.png');
  border-top: #86b649 5px solid;
}
.services-section {
  position: relative;
  color: white;
  text-align: center;
  padding: 40px;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto; /* Center the section */
}

.services-section h1 {
  margin-bottom: 50px;
  color: #86b649;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Fixed 3 columns */
  gap: 20px; /* Space between grid items */
  margin-top: 20px;

  padding: 20px;
}

.service-item {
  border: #86b649 5px solid;
  padding: 20px;
  text-align: center;
  background-color: rgba(0, 77, 61, 0.8);
}

.service-item h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #86b649;
}
/* Learn More Button */
.learn-more-button {
  display: inline-block;
  padding: 12px 24px;
  margin-top: 20px;
  background-color: #86b649; /* Button background color */
  color: #fff; /* Text color */
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.learn-more-button:hover {
  background-color: #00332a; /* Darker shade on hover */
}

@media screen and (max-width: 768px) {
  .hero{
    background-image: url('@/assets/images/alt-hero-small.jpg');
  }
  .services-grid {
    grid-template-columns: 1fr; /* Single column on smaller screens */
    padding: 5px;
  }
  
}
  </style>
  