<template>
  <div class="hero">
    <div class="hero-content">      
      <h1>About Us</h1>
    </div>
  </div>
  <div class="heading">
      <h1>We strive to be Adelaide's go to provider for tree and garden services</h1>
    </div>
    <div class="container">
  <div class="about-us">
    <img src="@/assets/images/1000002000.jpg" alt="Picture of Danny hard at work">
    <div class="text">    
      <p>At Freedom Tree and Garden Services, we take pride in offering professional, reliable, and affordable garden care and tree services across Adelaide. Whether you need help with garden clean-ups, tree removal, hedge trimming, or general maintenance, our experienced team is here to make sure your outdoor space looks its best year-round.</p>
      <p>With years of experience in the industry, we’ve built a reputation for quality workmanship and outstanding customer service. We understand that your garden is an important part of your home, and we treat every project—big or small—with care and attention to detail. Our goal is to make sure you’re satisfied with the results, knowing that you’ve hired a team you can trust to get the job done right.</p>
      <p>We also believe in providing affordable services without cutting corners. We work efficiently to ensure that you receive the best value for your money, and we’re happy to provide a free, no-obligation quote upfront so there are no surprises.</p>
      <p>Fully licensed and insured, you can count on us to handle your job safely and professionally. From tree pruning to garden clean-ups and everything in between, we’re committed to delivering top-quality results with a hassle-free experience.</p>
      <p>When you choose Freedom Tree and Garden Services, you’re choosing a team that values honesty, hard work, and customer satisfaction. Let us take care of your outdoor needs so you can enjoy a clean, tidy, and beautiful garden without the stress.</p>
    </div>

  </div>
</div>
  <div>
    
  </div>
</template>
  <script>
  export default {
    name: 'AboutUsPage'
  };
  </script>
  
  <style scoped>
.hero {
  width: 100%;
  height: 50vh;
  background-image: url('@/assets/images/alt-hero.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center; /* Vertically centers the content */
  justify-content: center; /* Horizontally centers the content */
  border-bottom: #86b649 5px solid;
}

.hero-content {
  text-align: center;
  color: white; /* Optional: change text color if needed */
}

.hero h1 {
  margin: 0; /* Remove any default margins from h1 */
  font-size: 3em; /* Adjust the size to fit your design */

}
.heading {
  text-align: center;
  margin: 50px;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.about-us{
  text-align: center;
  display: flex;
  max-width: 1000px;
  text-align: left;
  gap: 50px;
  margin: 50px
  

}
.text{
  text-align: left;
  max-width: 500px;
  margin: 0;
}
.text p {
  margin-top: 0;
}

@media  screen and (max-width: 768px) {
  .hero{
    background-image: url('@/assets/images/alt-hero-small.jpg');
  }
  .about-us {
    flex-direction: column;
  }
  .about-us img {
    width: 100%;
  }
  .text {
    max-width: 100%;
  }
  .heading {
    margin: 10px;
  }
  
}
  </style>
  